<template>
  <div class="jy_index">
    <Carousel
      :list="basicData.lbList"
      class="zz_Carousel"
      v-if="Object.keys(basicData).length != 0"
    >
    </Carousel>
    <div class="jy_course">
      <div class="jy_course_head">
        <img :src="basicData.kc_img" alt="" />
      </div>
      <ul class="jy_course_list">
        <li v-for="(item, idx) in kcList" :key="idx">
          <a :href="`#/jy_course?currentId=${item.id}`" class="zz_all_img">
            <div class="li_img">
              <img :src="item.site_images" alt="" />
            </div>
            <p class="ellipsis">{{ item.title }}</p>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="jy_fuwu"
      :style="{
        background: `url(${basicData.fuwuBg}) center center no-repeat`
      }"
    >
      <div class="jy_fuwu_nav">
        <div class="nav_col">
          <div class="nav_col_log">
            <img :src="ysData.ys_bg" alt="" />
          </div>
          <div class="nav_col_text">
            <h2>{{ ysData.ys_title }}</h2>
            <h4>{{ ysData.ys_yw }}</h4>
            <div class="text_html" v-html="ysData.ys_html"></div>
          </div>
        </div>
        <div class="nav_col col2">
          <div class="nav_col_text">
            <h2>{{ bhData.bh_title }}</h2>
            <h4>{{ bhData.bh_yw }}</h4>
            <div class="text_html" v-html="bhData.bh_html"></div>
          </div>
          <div class="nav_col_log">
            <img :src="bhData.bh_bg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div
      class="jy_about"
      :style="{
        background: `url(${aboutData.gyBg}) center center no-repeat`
      }"
    >
      <div class="jy_about_nav">
        <div class="nav_col1 zz_all_img">
          <img :src="aboutData.gy_img" alt="" />
        </div>
        <div
          class="nav_col2"
          :style="{
            background: `url(${aboutData.gy_bg}) top center no-repeat`
          }"
        >
          <div class="nav_col2_nav">
            <h2>{{ aboutData.gy_title }}</h2>
            <h4>
              <a :href="`#/jy_aboutuser`" @click="toLink(88)">
                MORE+
              </a>
            </h4>
            <div class="col2_html" v-html="aboutData.gy_html"></div>
          </div>
        </div>
        <div class="nav_col3"></div>
      </div>
    </div>
    <div
      class="jy_biaoyu"
      :style="{
        background: `url(${byData.by_bg}) center center no-repeat`
      }"
    >
      <div
        class="jy_biaoyu_nav"
        :style="{
          background: `url(${byData.by_img}) center center no-repeat`
        }"
      >
        <h2>{{ byData.by_title }}</h2>
        <h4>{{ byData.by_text }}</h4>
        <a :href="`#/jy_aboutuser`" @click="toLink(88)">立即咨询</a>
      </div>
    </div>
    <div class="jy_news">
      <div class="jy_news_title">
        潜水动态
        <p>Diving News</p>
      </div>
      <ul class="jy_news_list">
        <li class="list_li" v-for="(item, idx) in newsList" :key="idx">
          <div class="li_head">
            <h2>{{ item.name }}</h2>
            <a :href="'#/jy_news?currentIdx=' + (idx + 1)">MORE+</a>
          </div>
          <div class="li_img zz_all_img">
            <a
              :href="
                '#/jy_news?currentIdx=' +
                  (idx + 1) +
                  '&currentId=' +
                  item.tuijian.id
              "
            >
              <img :src="item.tuijian.cover_image" alt="" />
            </a>
          </div>
          <ul class="li_list">
            <li v-for="(el, i) in item.news" :key="i">
              <a
                :href="
                  '#/jy_news?currentIdx=' + (idx + 1) + '&currentId=' + el.id
                "
              >
                > {{ el.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  getBasicMsg,
  getPageMsg,
  getXwData,
  getJyCaseList,
  getProductList
} from '@/api/api'
import Carousel from '@/common/Carousel/zzCarousel.vue'
export default {
  components: {
    Carousel
  },
  data () {
    return {
      basicData: {},
      ysData: {},
      bhData: {},
      kcList: [],
      aboutData: {},
      byData: {},
      newsList: []
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      getBasicMsg(this.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          getPageMsg(id).then(res => {
            if (res.data.code == 1) {
              that.basicData = res.data.data.basic
              that.ysData = res.data.data.优势
              that.bhData = res.data.data.包含
              that.aboutData = res.data.data.关于
              that.byData = res.data.data.标语
            }
          })
          getXwData(project).then(res => {
            if (res.data.code == 1) {
              let arr = []
              res.data.data.forEach(el => {
                if (el.news.length != 0) {
                  arr = []
                  el.news.forEach((val, i) => {
                    if (i < 4) {
                      arr.push(val)
                    }
                  })
                  el.news = arr
                }
              })
              that.newsList = res.data.data
            }
          })
          getJyCaseList(1, 3, project).then(res => {
            if (res.data.code == 1) {
              that.kcList = res.data.data.list
            }
          })
        }
      })
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.jy_index {
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  color: #212529;
  /deep/.zz_Carousel {
    overflow: hidden;
    img {
      line-height: 0;
      max-width: 100%;
      position: relative;
      animation: scaleAnim 3s ease forwards;
    }
  }
  .jy_course {
    width: 1100px;
    margin: 0 auto;
    padding-top: 60px;
    .jy_course_head {
      width: 100%;
      text-align: center;
    }
    .jy_course_list {
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(50% - 30px);
        padding: 0 15px;
        padding-top: 20px;
        .li_img {
          width: 100%;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        p {
          font-size: 20px;
          line-height: 2.5;
          text-align: center;
        }
      }
      li:nth-child(3) {
        width: 100%;
      }
    }
  }
  .jy_fuwu {
    width: 100%;
    margin: 0 auto;
    height: 850px;
    position: relative;
    display: flex;
    justify-content: center;
    .jy_fuwu_nav {
      position: absolute;
      top: 100px;
      background: url(~@/assets/image/all/neibeijing.png) bottom center
        no-repeat;
      width: 1100px;
      .nav_col {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        .nav_col_log {
          width: calc(50% - 15px);
          padding-left: 15px;
          img {
            width: 100%;
          }
        }
        .nav_col_text {
          width: calc(50% - 15px);
          padding-right: 15px;
          position: relative;
          text-align: center;
          padding-top: 50px;
          opacity: 0.8;
          background-color: #fff;
          h2 {
            position: relative;
            display: inline-block;
            font-size: 20px;
            color: #0993c4;
            font-weight: bold;
            line-height: 40px;
            margin: 0px;
          }
          h2::before {
            content: '';
            position: absolute;
            width: 45px;
            height: 4px;
            background: #0993c4;
            top: 50%;
            margin-top: -2px;
            left: -55px;
          }
          h2::after {
            content: '';
            position: absolute;
            width: 45px;
            height: 4px;
            background: #0993c4;
            top: 50%;
            margin-top: -2px;
            right: -55px;
          }
          h4 {
            font-size: 16px;
            color: #999999;
            line-height: 32px;
            margin: 0px;
          }
          .text_html {
            color: #666;
            line-height: 1.8;
          }
        }
        .nav_col_text::after {
          content: '';
          position: absolute;
          left: -18px;
          top: 78px;
          width: 0;
          height: 0;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-right: 18px solid #f5f5f5;
          opacity: 0.8;
        }
      }
      .col2 {
        padding-top: 18px;
        padding-bottom: 25px;
        .nav_col_log {
          padding-left: 0;
          padding-right: 15px;
        }
        .nav_col_text {
          padding-right: 0;
          padding-left: 15px;
        }
        .nav_col_text::after {
          left: auto;
          right: -18px;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 18px solid #f5f5f5;
          border-right: 0;
        }
      }
    }
  }
  .jy_about {
    width: 100%;
    padding-bottom: 50px;
    .jy_about_nav {
      height: 520px;
      position: relative;
      width: 1100px;
      margin: 0 auto;
      padding-top: 100px;
      .nav_col1 {
        position: absolute;
        right: -20px;
        z-index: 9;
        img {
          width: 100%;
        }
      }
      .nav_col2 {
        width: 520px;
        position: absolute;
        z-index: 99;
        margin-top: 20px;
        color: #fff;
        background-size: cover !important;
        .nav_col2_nav {
          padding: 10px 34px 50px 34px;
          h2 {
            font-size: 32px;
            line-height: 2;
            color: #ffffff;
            font-weight: bold;
            margin: 0px;
          }
          h4 {
            border-bottom: 1px solid #ffffff;
            padding-bottom: 20px;
            font-size: 20px;
            text-align: right;
            a {
              font-size: 14px;
              color: #c1f7ff;
              border-bottom: 1px solid #c1f7ff;
            }
          }
          .col2_html {
            margin-top: 15px;
            line-height: 1.8;
          }
        }
      }
      .nav_col3 {
        z-index: 99;
        position: absolute;
        bottom: 0;
      }
    }
  }
  .jy_biaoyu {
    width: 100%;
    margin: 0 auto;
    height: 580px;
    max-height: 580px;
    background-attachment: fixed !important;
    position: relative;
    margin-top: 20px;
    .jy_biaoyu_nav {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      border-radius: 7px;
      padding: 50px;
      color: #fff;
      margin-left: -370px;
      margin-top: -105.3px;
      h2 {
        font-size: 36px;
        font-weight: bold;
      }
      h4 {
        font-size: 30px;
        font-weight: bold;
        line-height: 3.5;
      }
      a {
        font-size: 16px;
        color: #fff;
        background: #3eccff;
        border-radius: 2px;
        padding: 10px 50px;
        display: inline-block;
      }
    }
  }
  .jy_news {
    width: 1100px;
    margin: 0 auto;
    .jy_news_title {
      padding-top: 50px;
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      p {
        font-size: 24px;
        color: #ddd;
        line-height: 1;
      }
    }
    .jy_news_list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 50px;
      .list_li {
        width: calc(33.33% - 15px);
        margin-top: 20px;
        border: 1px solid #ddd;
        .li_head {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          h2 {
            font-weight: bold;
          }
          a {
            color: #493228;
            font-size: 14px;
          }
          a:hover {
            color: #0993c4;
          }
        }
        .li_img {
          width: 100%;
          height: 200px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .li_list {
          padding: 5px 20px;
          li {
            a {
              font-size: 14px;
              line-height: 2.4;
              color: #666666;
            }
            a:hover {
              color: #0993c4;
            }
          }
        }
      }
    }
  }
}
@keyframes scaleAnim {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
