var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jy_index"},[(Object.keys(_vm.basicData).length != 0)?_c('Carousel',{staticClass:"zz_Carousel",attrs:{"list":_vm.basicData.lbList}}):_vm._e(),_c('div',{staticClass:"jy_course"},[_c('div',{staticClass:"jy_course_head"},[_c('img',{attrs:{"src":_vm.basicData.kc_img,"alt":""}})]),_c('ul',{staticClass:"jy_course_list"},_vm._l((_vm.kcList),function(item,idx){return _c('li',{key:idx},[_c('a',{staticClass:"zz_all_img",attrs:{"href":`#/jy_course?currentId=${item.id}`}},[_c('div',{staticClass:"li_img"},[_c('img',{attrs:{"src":item.site_images,"alt":""}})]),_c('p',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.title))])])])}),0)]),_c('div',{staticClass:"jy_fuwu",style:({
      background: `url(${_vm.basicData.fuwuBg}) center center no-repeat`
    })},[_c('div',{staticClass:"jy_fuwu_nav"},[_c('div',{staticClass:"nav_col"},[_c('div',{staticClass:"nav_col_log"},[_c('img',{attrs:{"src":_vm.ysData.ys_bg,"alt":""}})]),_c('div',{staticClass:"nav_col_text"},[_c('h2',[_vm._v(_vm._s(_vm.ysData.ys_title))]),_c('h4',[_vm._v(_vm._s(_vm.ysData.ys_yw))]),_c('div',{staticClass:"text_html",domProps:{"innerHTML":_vm._s(_vm.ysData.ys_html)}})])]),_c('div',{staticClass:"nav_col col2"},[_c('div',{staticClass:"nav_col_text"},[_c('h2',[_vm._v(_vm._s(_vm.bhData.bh_title))]),_c('h4',[_vm._v(_vm._s(_vm.bhData.bh_yw))]),_c('div',{staticClass:"text_html",domProps:{"innerHTML":_vm._s(_vm.bhData.bh_html)}})]),_c('div',{staticClass:"nav_col_log"},[_c('img',{attrs:{"src":_vm.bhData.bh_bg,"alt":""}})])])])]),_c('div',{staticClass:"jy_about",style:({
      background: `url(${_vm.aboutData.gyBg}) center center no-repeat`
    })},[_c('div',{staticClass:"jy_about_nav"},[_c('div',{staticClass:"nav_col1 zz_all_img"},[_c('img',{attrs:{"src":_vm.aboutData.gy_img,"alt":""}})]),_c('div',{staticClass:"nav_col2",style:({
          background: `url(${_vm.aboutData.gy_bg}) top center no-repeat`
        })},[_c('div',{staticClass:"nav_col2_nav"},[_c('h2',[_vm._v(_vm._s(_vm.aboutData.gy_title))]),_c('h4',[_c('a',{attrs:{"href":`#/jy_aboutuser`},on:{"click":function($event){return _vm.toLink(88)}}},[_vm._v(" MORE+ ")])]),_c('div',{staticClass:"col2_html",domProps:{"innerHTML":_vm._s(_vm.aboutData.gy_html)}})])]),_c('div',{staticClass:"nav_col3"})])]),_c('div',{staticClass:"jy_biaoyu",style:({
      background: `url(${_vm.byData.by_bg}) center center no-repeat`
    })},[_c('div',{staticClass:"jy_biaoyu_nav",style:({
        background: `url(${_vm.byData.by_img}) center center no-repeat`
      })},[_c('h2',[_vm._v(_vm._s(_vm.byData.by_title))]),_c('h4',[_vm._v(_vm._s(_vm.byData.by_text))]),_c('a',{attrs:{"href":`#/jy_aboutuser`},on:{"click":function($event){return _vm.toLink(88)}}},[_vm._v("立即咨询")])])]),_c('div',{staticClass:"jy_news"},[_vm._m(0),_c('ul',{staticClass:"jy_news_list"},_vm._l((_vm.newsList),function(item,idx){return _c('li',{key:idx,staticClass:"list_li"},[_c('div',{staticClass:"li_head"},[_c('h2',[_vm._v(_vm._s(item.name))]),_c('a',{attrs:{"href":'#/jy_news?currentIdx=' + (idx + 1)}},[_vm._v("MORE+")])]),_c('div',{staticClass:"li_img zz_all_img"},[_c('a',{attrs:{"href":'#/jy_news?currentIdx=' +
                (idx + 1) +
                '&currentId=' +
                item.tuijian.id}},[_c('img',{attrs:{"src":item.tuijian.cover_image,"alt":""}})])]),_c('ul',{staticClass:"li_list"},_vm._l((item.news),function(el,i){return _c('li',{key:i},[_c('a',{attrs:{"href":'#/jy_news?currentIdx=' + (idx + 1) + '&currentId=' + el.id}},[_vm._v(" > "+_vm._s(el.title)+" ")])])}),0)])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jy_news_title"},[_vm._v(" 潜水动态 "),_c('p',[_vm._v("Diving News")])])
}]

export { render, staticRenderFns }